<template>
  <div class="flex items-center">
    <component
      :is="socialIconMap[props.socialType]"
      :width="props.size"
      :height="props.size"
    />
    <span class="ml-2 font-semibold">
      {{ ContentSocialTypeLabelMap[props.socialType] }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { ContentSocialType, ContentSocialTypeLabelMap } from '~/api/contents';
import FacebookLogoSvg from '@/assets/images/social-media/facebook-logo.svg?component';
import TwitterLogoSvg from '@/assets/images/social-media/twitter-logo.svg?component';
import LinkedinLogoSvg from '@/assets/images/social-media/linkedin-logo.svg?component';
import InstagramLogoSvg from '@/assets/images/social-media/instagram-logo.svg?component';
import { SocialIconSize } from '~/pages/_components/social-type-info/social-type-info.constants';

type Props = {
  socialType: ContentSocialType;
  size?: SocialIconSize;
};

const props = withDefaults(defineProps<Props>(), {
  size: SocialIconSize.MD,
});

const socialIconMap: Record<ContentSocialType, unknown> = {
  // [ContentSocialType.TWITTER]: TwitterLogoSvg,
  [ContentSocialType.FACEBOOK]: FacebookLogoSvg,
  [ContentSocialType.LINKEDIN]: LinkedinLogoSvg,
  [ContentSocialType.INSTAGRAM]: InstagramLogoSvg,
};
</script>
