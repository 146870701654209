import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "40",
  height: "40",
  fill: "none",
  viewBox: "0 0 40 40"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "20",
      cy: "20",
      r: "20",
      fill: "#FF0076"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#fff",
      d: "M24.48 10h-8.96A5.525 5.525 0 0 0 10 15.52v8.96A5.525 5.525 0 0 0 15.52 30h8.96A5.526 5.526 0 0 0 30 24.48v-8.96A5.525 5.525 0 0 0 24.48 10m3.746 14.48a3.75 3.75 0 0 1-3.745 3.745h-8.962a3.75 3.75 0 0 1-3.745-3.744v-8.962a3.75 3.75 0 0 1 3.745-3.745h8.962a3.75 3.75 0 0 1 3.744 3.745z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#fff",
      d: "M20 14.846A5.16 5.16 0 0 0 14.847 20 5.16 5.16 0 0 0 20 25.153 5.16 5.16 0 0 0 25.153 20 5.16 5.16 0 0 0 20 14.846m0 8.532A3.383 3.383 0 0 1 16.62 20 3.383 3.383 0 0 1 20 16.62 3.383 3.383 0 0 1 23.379 20a3.383 3.383 0 0 1-3.38 3.378M25.37 13.342a1.307 1.307 0 0 0-1.302 1.302c0 .342.14.677.382.92.242.241.578.38.92.38s.677-.139.92-.38a1.3 1.3 0 0 0 .38-.92c0-.344-.138-.68-.38-.92a1.3 1.3 0 0 0-.92-.382"
    }, null, -1)
  ])))
}
export default { render: render }