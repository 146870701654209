import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "40",
  height: "40",
  fill: "none",
  viewBox: "0 0 40 40"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "20",
      cy: "20",
      r: "20",
      fill: "#0076B3"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#fff",
      d: "M27.671 11h-15.34A1.31 1.31 0 0 0 11 12.29v15.42A1.31 1.31 0 0 0 12.33 29H27.67A1.31 1.31 0 0 0 29 27.716v-15.42a1.31 1.31 0 0 0-1.33-1.29zM16.457 26.07h-2.719v-8.13h2.72zm-1.359-9.24h-.018a1.555 1.555 0 1 1 .018 0m11.164 9.24h-2.72v-4.35c0-1.093-.393-1.838-1.378-1.838a1.49 1.49 0 0 0-1.395.988c-.069.213-.099.436-.088.66v4.54h-2.72s.037-7.368 0-8.13h2.72v1.152a2.7 2.7 0 0 1 2.45-1.342c1.789 0 3.13 1.163 3.13 3.66z"
    }, null, -1)
  ])))
}
export default { render: render }